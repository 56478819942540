module services {
    export module costing {

        export class costParameterOriginCountryService implements interfaces.costing.ICostParameterOriginCountryService {

            static $inject = ["$resource", "ENV"];

            constructor(private $resource: ng.resource.IResourceService, private ENV: interfaces.applicationcore.serverConfig) {
            }

            getOriginCountryList(): ng.resource.IResourceClass<interfaces.costing.ICostOriginCountry> {
                return this.$resource<interfaces.costing.ICostOriginCountry>(this.ENV.DSP_URL + "CostParameterOriginCountry/GetOriginCountryList", {
                    showAllActive: '@showAllActive',
                    entityId: '@entityId',
                    countryId: '@countryId'
                }, {
                        query: {
                            method: 'GET',
                            isArray: true,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates },
                        }
                    });
            }

            saveOriginCountry(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "CostParameterOriginCountry/SaveOriginCountry", {
                });
            }

            removeOriginCountry(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "CostParameterOriginCountry/RemoveOriginCountry", {
                    cpuId: '@cpuId',
                });
            }


        }

    }

    angular.module("app").service("costParameterOriginCountryService", services.costing.costParameterOriginCountryService);
}